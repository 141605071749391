.equal-width-columns {
  flex: 1; // Makes columns flex items with equal width
}

.fit-content-column {
  width: auto !important;
  flex: 0 0 auto !important;
}

.even {
  background-color: rgba(0, 0, 0, 0.075);
}

.inline-input {
  input {
    margin: 0px !important;
  }
}

.photo-buttons {
  display: flex;
  justify-content: space-around;
  padding-top: 1rem;
}

.submit {
  display: flex;
  justify-content: center;
  padding-top: 1rem;
}

.uploaded-check {
  position: absolute;
  top: 10px;
  right: 10px;
}

.card-icon {
  padding-bottom: 0.5rem;
}

.card-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 1rem;
}

.documents-card {
  margin-right: 1rem;
  margin-top: 1rem;
  border-color: var(--theme-green);
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.2);
  border-width: 1.5px;
  border-style: solid;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 12px;
  position: relative;
}

.loader-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
}

.loads-box {
  margin: 1rem;
  margin-left: 0rem;
  margin-right: 0rem;
  border-radius: 5px;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.2);
  max-height: calc(100vh - 250px);  /* Adjust this value as needed */
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.columns {
  padding: 0.5rem;
  border-bottom: 1px solid var(--light-grey);
}

.dropdown {
  button {
    background-color: var(--theme-blue);
    border-color: var(--theme-blue);
    padding-top: 3px;
    padding-bottom: 3px;
  }

  div {
    button {
      background-color: white;
    }

    --bs-dropdown-link-active-bg: var(--theme-green);
  }

  margin-left: auto;
  margin-right: 1rem;
}