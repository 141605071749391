.toggle {
  width: 50px;
  height: 25px;
  background-color: #ccc;
  border-radius: 25px;
  position: relative;
  transition: background-color 0.2s;

  &::after {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    background-color: white;
    transition: transform 0.2s;
  }

  &.on {
    background-color: var(--theme-green);

    &::after {
      transform: translateX(25px);
    }
  }

  &.off {
    background-color: var(--theme-grey);
  }
}
