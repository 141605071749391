.price-list {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;

  h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.fuel-levy {
  margin-bottom: 20px;

  label {
    margin-right: 10px;
  }

  input {
    width: 60px;
    padding: 5px;
  }
}

.price-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 30px;

  th, td {
    border: 1px solid #ddd;
    padding: 0px 10px;
    text-align: left;
  }

  th {
    background-color: #f2f2f2;
    font-weight: bold;
  }

  .base-rate-input {
    width: 80px;
    padding: 5px;
  }

  td:last-child {
    width: 150px;
  }
}

h3 {
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  color: var(--off-black);
}

.footer {
  display: flex;
  justify-content: center;
}

.date-selection {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
}

.date-dropdown {
  margin: 4px;
  box-sizing: border-box;
  padding: 5px 10px;
  background-color: rgba(0, 0, 0, 0);
  border: 1.5px solid var(--theme-green);
  border-radius: 5px;
  outline: none;
  color: var(--off-black);
  input {
    border: none;
    outline: none;
  }
}

.loader {
  display: flex;
  justify-content: center;
}