.create-new-user {
  padding: 1rem;
  min-width: 500px;

  h6 {
    margin-bottom: 1.5rem;
    color: var(--theme-blue);
    text-align: center;
  }
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;

  div:first-child {
    color: var(--theme-blue);
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
}

.permissions-section {
  margin: 20px 0;

  h6 {
    margin-bottom: 10px;
    font-weight: 600;
  }
}

.permissions-section-item {
  display: flex;
  align-items: center;
  gap: 1rem;
}